import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Container = styled.div`
    width: 100%;
    padding: 0 18px;
    @media (min-width: 1140px) {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0;
    }
`

export const TitleComponent = styled(Typography)`
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.2px;
`