import React from 'react'
import { CustomButtonWrapper } from './styles'

interface CustomButtonProps {
    children: React.ReactNode
    fillColor?: string
    backgroundColor?: string
    color?: string
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, fillColor, backgroundColor, color }) => {
    return (
        <CustomButtonWrapper width="100%" fillColor={fillColor} backgroundColor={backgroundColor} color={color}>
            <p>{children}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32">
                <path d="M22.8878 4.71202L18.2689 0L10.7775 7.64238L9.63998 3.23512L3.33206 4.93472L6.04366 15.4137L1.71199 14.2181L0 20.6415L10.2834 23.4898L7.10073 26.7132L11.7082 31.4369L19.2455 23.7945L20.3945 28.1901L26.7024 26.4787L23.9678 16.0115L28.2995 17.1954L30 10.7603L19.7396 7.94713L22.9108 4.71202H22.8878ZM17.499 14.2416L22.9223 15.7302L17.499 17.2071L18.9468 22.7396L14.9943 18.684L11.0073 22.7279L12.478 17.1954L7.07775 15.695L12.478 14.2416L11.0532 8.74419L14.9828 12.7529L18.9468 8.70903L17.499 14.2181V14.2416Z" />
            </svg>
        </CustomButtonWrapper>
    )
}

export default CustomButton