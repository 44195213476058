import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function HelperLanguage() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en")

  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectAndClose = (lang: string) => {
    setSelectedLanguage(lang)
    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: "#fff"}}
        endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <g clipPath="url(#clip0_2821_503)">
          <path d="M4.5 7.25L9 11.75L13.5 7.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2821_503">
            <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
        </defs>
      </svg>}
      >
        {selectedLanguage}
    </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        elevation={0}
        sx={{
          borderRadius: "10px"
        }}
      >
        <MenuItem onClick={() => {selectAndClose('en')}}>Eng</MenuItem>
        <MenuItem onClick={() => {selectAndClose('ru')}}>Rus</MenuItem>
        <MenuItem onClick={() => {selectAndClose('uz')}}>Uzb</MenuItem>
      </Menu>
    </div>
  );
}
