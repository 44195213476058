import React, { lazy } from 'react'
// import data from '../mock_data/data.json';
const Welcome = lazy(() => import('../components/Welcome'));
const FAQ = lazy(() => import('../components/FAQ'));
const Personalized = lazy(() => import('../components/Personalized'));
const PersonalizedBottom = lazy(() => import('../components/PersonalizedBottom'));
const Partners = lazy(() => import('../components/Partners'));
const OurTeachers = lazy(() => import('../components/OurTeachers'));
const Impressive = lazy(() => import('../components/Impressive'));
const WhyUs = lazy(() => import('../components/WhyUs'))
const OurTeachersInfo = lazy(() => import('../components/OurTeachersInfo'))
const Students = lazy(() => import('../components/Students'))


const MainPage: React.FC = () => {

  return (
    <>
      <Welcome/>
      <Students/>
      <Impressive/>
      <WhyUs/>
      <OurTeachers title="Offline courses" description="We have diverse types of courses, so you will find perfect fit course for yourself" courses={[]}/>
      <OurTeachers title="Online courses" description="We have diverse types of courses, so you will find perfect fit course for yourself" courses={[]}/>
      <Personalized/>
      <PersonalizedBottom/>
      <OurTeachersInfo/>
      <FAQ/>
      <Partners/>
    </>
  )
}

export default MainPage