import styled from "@emotion/styled";

export const TeacherCourseBox = styled.div`
    width: 320px;
    /* height: 588px; */
    border-radius: 20px;
    border: 3px solid #AA233B;
    background: #FFF;
    padding: 17px;
    transition: all 0.5s ease;
    .teache-course-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        padding-top: 4px;
        padding-bottom: 30px;
        svg {
            fill: #000;
        }
        h5 {
            color: #000;
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 28.5px;
            letter-spacing: -0.9px;
        }
    }
    ul.teache-course-info-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 35px;
        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 14px;
            span {
                width: 20px;
                height: 20px;
                svg {
                    path {
                        fill: dark;
                    }
                }
            }
            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 19.2px;
                letter-spacing: -0.48px;
            }
        }
    }
    :hover {
        background-color: #AA233B;
        .teache-course-header {
            h5 {
                color: #fff;
            }
            svg {
                fill: #fff;
            }
        }
        ul.teache-course-info-list {
            li {
                span {
                    svg {
                        color: #fff;
                        path {
                            color: #FFF;
                        }
                    }
                }
                p {
                    color: #fff;
                }
            }
        }
    }
`

