import styled from "@emotion/styled";

export const PartnerImgeBox = styled.div`
    width: 100px;
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: inline-block;
        width: 100%;
        max-width: 250px;
    }
`