import styled from "@emotion/styled";
import welcome_bg_image from "../media/welcome_bg_image.png";
import login_bg from "../media/login_bg.png";
import { Link } from "react-router-dom";

// Enrole Page 

export const EnroleWrapper = styled.div`
    width: 100%;
    background-image: url(${welcome_bg_image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 1000px) {
        background-image: url(${login_bg});
        background-position: left top;
    }
`

export const EnroleContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 54px);
    padding: 75px 18px;
    @media (min-width: 1140px) {
        max-width: 1100px;
        margin: 0 auto;
        padding: 110px 0;
    }
    @media (min-width: 1100px) {
        min-height: calc(100vh - 80px);
    }
`

export const EnroleBox = styled.div`
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(7.5px);
    padding: 23px 10px;
    @media screen and (min-width: 1000px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 50px;
        padding: 30px;
    }
`

export const EnroleInfo = styled.div`
    margin-bottom: 10px;
    h5 {
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 57.6px;
        letter-spacing: -1.44px;
    }
    @media screen and (min-width: 1000px) {
        width: 50%;
    }
`

export const EnroleLinks = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`

export const EnroleLink = styled(Link)`
    color: rgba(255, 255, 255, 0.60);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14.4px;
    letter-spacing: -0.36px;
`

export const EnroleLinkPoint = styled.div`
    width: 5px;
    height: 5px;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.60);
    border-radius: 50%;
`

export const EnroleForm = styled.div`
    border-radius: 20px;
    background: #FFF;
    padding: 45px 10px;
    p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.5;
        padding-top: 15px;
    }
    @media screen and (min-width: 1000px) {
        width: 50%;
    }
`

export const EnroleFormElements = styled.div`
    padding: 10px 50px 35px 50px;
`


// Not Found Page

export const NotFoundWrapper = styled.div`
    width: 100%;
    background-image: url(${login_bg});
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    @media (min-width: 1140px) {
        max-width: 1100px;
        margin: 0 auto;
        padding: 110px 0;
    }
    @media (min-width: 1100px) {
        min-height: calc(100vh - 80px);
    }
`

export const NotFoundBox = styled.div`
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(7.5px);
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
    padding: 46px 10px;
`

export const NotFoundImg = styled.img`
    width: 100%;
    max-width: 500px;
`