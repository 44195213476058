import { Box } from '@mui/material'
import React from 'react'
import { FullTuition, FullTuitionText, StudentBox, StudentBoxImage, StudentBoxName, StudentImgInfo, StudentList, StudentResultsWrapper, UniversitetBox, UniversitetBoxInfo, UniversitetBoxInfoIcon, UniversitetBoxInfoText } from './styless'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';

const StudentResults: React.FC = () => {
    return (
        <StudentResultsWrapper>
            <StudentList>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={20}
                    // direction={'vertical'}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    breakpoints={{
                        700: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                            direction: "vertical"
                        },
                    }}
                >
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("1") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("2") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("3") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("4") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("5") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("6") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("7") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("8") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <StudentBox
                            onClick={() => { console.log("9") }}
                        >
                            <StudentBoxImage src={require("./../../media/user_iconc.png")} alt="Image description" />
                            <StudentBoxName>
                                <p>Ethan Williams</p>
                                <span>IELTS 8.5</span>
                            </StudentBoxName>
                        </StudentBox>
                    </SwiperSlide>
                </Swiper>
            </StudentList>
            {/* </Box> */}
            <StudentImgInfo>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingBottom: "15px",
                        'img': {
                            flex: 1,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: "20px"
                        },
                        '@media screen and (min-width: 700px)': {
                            paddingBottom: "0"
                        }
                    }}
                >
                    <img src={require('../../media/studentimage.png')} alt="student" />
                </Box>
                <Box>
                    <Box
                        maxWidth="300px"
                        paddingBottom="30px"
                        sx={{
                            '@media screen and (min-width: 1020px)': {
                                maxWidth: "450px"
                            }
                        }}
                    >
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor doloribus incidunt sit deleniti aliquid tenetur ad et ratione reprehenderit sint eum, soluta voluptas a quas ex magnam, nostrum quos. Magnam?</p>
                        {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor doloribus incidunt sit deleniti aliquid tenetur ad et ratione reprehenderit sint eum, soluta voluptas a quas ex magnam, nostrum quos. Magnam?</p> */}
                        {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor doloribus incidunt sit deleniti aliquid tenetur ad et ratione reprehenderit sint eum, soluta voluptas a quas ex magnam, nostrum quos. Magnam?</p> */}
                        {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor doloribus incidunt sit deleniti aliquid tenetur ad et ratione reprehenderit sint eum, soluta voluptas a quas ex magnam, nostrum quos. Magnam?</p> */}
                        {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor doloribus incidunt sit deleniti aliquid tenetur ad et ratione reprehenderit sint eum, soluta voluptas a quas ex magnam, nostrum quos. Magnam?</p> */}
                    </Box>
                    <UniversitetBox>
                        <img src={require('../../media/universitetimage.png')} alt="universitet" />
                        <UniversitetBoxInfo>
                            <UniversitetBoxInfoText>
                                <span>Accepted</span>
                                <p>Harvard</p>
                            </UniversitetBoxInfoText>
                            <UniversitetBoxInfoIcon>
                                <Box
                                    alignSelf="self-start"
                                >
                                    <Box
                                        sx={{
                                            animation: "spin 2s linear infinite",
                                            "@keyframes spin": {
                                                "0%": {
                                                    transform: "rotate(360deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(0deg)",
                                                },
                                            },
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                            <path d="M26.7024 5.24609L21.3137 0L12.5737 8.50858L11.2466 3.60179L3.8874 5.49403L7.05094 17.1607L1.99732 15.8296L0 22.981L11.9973 26.1521L8.28418 29.7409L13.6595 35L22.4531 26.4914L23.7936 31.3852L31.1528 29.4799L27.9625 17.8263L33.0161 19.1443L35 11.9799L23.0295 8.84787L26.7292 5.24609H26.7024ZM20.4156 15.8557L26.7426 17.5131L20.4156 19.1573L22.1046 25.3169L17.4933 20.8016L12.8418 25.3039L14.5576 19.1443L8.25738 17.4739L14.5576 15.8557L12.8954 9.73527L17.4799 14.1984L22.1046 9.69612L20.4156 15.8296V15.8557Z" fill="black" />
                                        </svg>
                                    </Box>
                                </Box>
                            </UniversitetBoxInfoIcon>
                        </UniversitetBoxInfo>
                    </UniversitetBox>
                    <FullTuition>
                        <FullTuitionText>
                            <span>Scholarship</span>
                            <p>Full tuition</p>
                        </FullTuitionText>
                        <Box
                            alignSelf="self-start"
                            display="inline-block"
                        >
                            <Box
                                sx={{
                                    animation: "spin 2s linear infinite",
                                    "@keyframes spin": {
                                        "0%": {
                                            transform: "rotate(360deg)",
                                        },
                                        "100%": {
                                            transform: "rotate(0deg)",
                                        },
                                    },
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <path d="M26.7024 5.24609L21.3137 0L12.5737 8.50858L11.2466 3.60179L3.8874 5.49403L7.05094 17.1607L1.99732 15.8296L0 22.981L11.9973 26.1521L8.28418 29.7409L13.6595 35L22.4531 26.4914L23.7936 31.3852L31.1528 29.4799L27.9625 17.8263L33.0161 19.1443L35 11.9799L23.0295 8.84787L26.7292 5.24609H26.7024ZM20.4156 15.8557L26.7426 17.5131L20.4156 19.1573L22.1046 25.3169L17.4933 20.8016L12.8418 25.3039L14.5576 19.1443L8.25738 17.4739L14.5576 15.8557L12.8954 9.73527L17.4799 14.1984L22.1046 9.69612L20.4156 15.8296V15.8557Z" fill="white" />
                                </svg>
                            </Box>
                        </Box>
                    </FullTuition>
                </Box>
            </StudentImgInfo>
        </StudentResultsWrapper>
    )
}

export default StudentResults