import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { AccordionBodyText, AccordionHeaderText } from './styles';
import { Box } from '@mui/material';
import data from '../../mock_data/data.json';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Box>
            <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="35" height="35" rx="10" fill="#AA233B" />
                <g clipPath="url(#clip0_2858_2189)">
                    <path d="M18.002 11.5449V23.4546" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.0469 17.499H23.9566" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_2858_2189">
                        <rect width="20.4167" height="20.4167" fill="white" transform="translate(7.79297 7.29102)" />
                    </clipPath>
                </defs>
            </svg>

        </Box>}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row',
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "8px 0 16px 0"
}));

export default function FaqAccordion() {

    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            {
                data.faq.map((item, index) => (
                    <Box
                        key={index}
                    >
                        <Accordion
                        expanded={expanded === item.expanded}
                        onChange={handleChange(item.expanded)}
                    >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordionHeaderText>{item.question}</AccordionHeaderText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccordionBodyText>{item.answer}</AccordionBodyText>
                        </AccordionDetails>
                    </Accordion>
                    {item.divider && <Divider color="#000" sx={{ opacity: 0.5 }} />}
                    </Box>
                ))
            }

            {/* <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <AccordionHeaderText>
                        Can I get info about your <br /> digital platform online?
                    </AccordionHeaderText>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionBodyText>
                        MVP stands for Minimum Viable Product. MVP is the best way to check if your idea has a demand.
                        Will people use it or not? Mostly, this is a product with ONLY ONE function.
                    </AccordionBodyText>
                </AccordionDetails>
            </Accordion>
            <Divider color="#000" sx={{ opacity: 0.5 }} />
            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <AccordionHeaderText>Do you have guarantee that <br /> I can get 1400+?</AccordionHeaderText>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionBodyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
                        lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionBodyText>
                </AccordionDetails>
            </Accordion>
            <Divider color="#000" sx={{ opacity: 0.5 }} />
            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <AccordionHeaderText>From what age can I apply for SAT courses?</AccordionHeaderText>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionBodyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
                        lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionBodyText>
                </AccordionDetails>
            </Accordion>
            <Divider color="#000" sx={{ opacity: 0.5 }} />
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <AccordionHeaderText>Do you provide necessary literature or should I buy separately?</AccordionHeaderText>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionBodyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
                        lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionBodyText>
                </AccordionDetails>
            </Accordion> */}
        </div>
    );
}
