import styled from "@emotion/styled";
import welcome_bg_image from "../../media/welcome_bg_image.png";

export const WelcomeWrapper = styled.div`
    width: 100%;
    background-image: url(${welcome_bg_image});
    /* background-position: -241px bottom; */
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 840px){
        background-position: center top;
    }
`

export const WelcomeTextConatiner = styled.div`
    width: 100%;
    min-height: calc(100vh - 54px);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(180deg, rgba(208, 208, 208, 0.10) 0%, rgba(242, 242, 242, 0.20) 39.91%, rgba(252, 252, 252, 0.90) 77.79%, #FFF 100%);
`

export const WelcomeTextInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 55px;
    flex-direction: column;
    gap: 32px;
    h1 {
        color: #000;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 45.6px;
        letter-spacing: -1.44px;
    }
`

