import React from 'react'
import { ItemWrapper } from './styles'
import { Box, Typography } from '@mui/material'

const ItemOne: React.FC = () => {
  return (
    <ItemWrapper>
        <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{overflow: 'hidden'}}
        >
            <Typography
                color="#FFF"
                fontSize="56px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="56px"
                letterSpacing="-1.68px"
            >
                Why us?
            </Typography>
        </Box>
    </ItemWrapper>
  )
}

export default ItemOne