import styled from "@emotion/styled";

export const FooterWrapper = styled.footer`
    background-color: #000;
`

export const FooterNavList = styled.ul`
    padding-top: 20px;
    list-style: none;
    margin-bottom: 40px;
`

export const FooterNavListItem = styled.li`
    color: #fff;
    /* font-family: "Myriad Variable Concept"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.9px;
    letter-spacing: -0.14px;
    opacity: 0.5;
    padding-bottom: 5px;
    a {
        color: inherit;
    }
`