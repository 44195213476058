import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const AccordionHeaderText = styled(Typography)`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
`

export const AccordionBodyText = styled(Typography)`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.9px;
    letter-spacing: -0.28px;
`