import React from "react";
import Slider from "react-slick";
import { PartnerImgeBox } from "./styles";
import { Box } from "@mui/material";

const PartnersSlick: React.FC = () => {
    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 5,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <Box
            overflow="hidden"
        >
            <Slider {...settings}>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner1.png")} alt="partner1" />
                </PartnerImgeBox>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner2.png")} alt="partner1" />
                </PartnerImgeBox>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner3.png")} alt="partner1" />
                </PartnerImgeBox>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner4.png")} alt="partner1" />
                </PartnerImgeBox>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner5.png")} alt="partner1" />
                </PartnerImgeBox>
                <PartnerImgeBox>
                    <img loading="lazy" width="100px" height="80px" src={require("./../../media/partner6.png")} alt="partner1" />
                </PartnerImgeBox>
            </Slider>
        </Box>
    );
}

export default PartnersSlick;
