import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`

export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: ${props => props.theme.colors.primary};
`

export const LoaderIconc = styled.span`
    animation: ${rotate} 2s linear infinite;
    transform-origin: 70px 70px;
    
`