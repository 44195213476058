import React from 'react'
import { LoaderIconc, LoaderWrapper } from './styles'

const Loading: React.FC = () => {
    return (
        <LoaderWrapper>
            <LoaderIconc>
                <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
                    <g filter="url(#filter0_d_2789_1810)">
                        <path d="M103.18 19.4855L83.1651 0L50.7024 31.6033L45.7733 13.3781L18.4389 20.4064L30.1892 63.7397L11.4186 58.7957L4 85.3579L48.5615 97.1365L34.7698 110.466L54.7354 130L87.3972 98.3967L92.3761 116.573L119.71 109.497L107.861 66.2118L126.631 71.1074L134 44.4966L89.5381 32.8635L103.28 19.4855H103.18ZM79.8292 58.8926L103.33 65.0485L79.8292 71.1559L86.1026 94.0343L68.9751 77.2632L51.6982 93.9858L58.0712 71.1074L34.6703 64.903L58.0712 58.8926L51.8974 36.1596L68.9253 52.7368L86.1026 36.0142L79.8292 58.7957V58.8926Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_d_2789_1810" x="0" y="0" width="138" height="138" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2789_1810" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2789_1810" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </LoaderIconc>
        </LoaderWrapper>
    )
}

export default Loading