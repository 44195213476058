import React from 'react'
import { Container, TitleComponent } from '../../global_styles/styles'
import { Box, Typography } from '@mui/material'
import OurTeachersInfoSwiper from './OurTeachersInfoSwiper'

const OurTeachersInfo: React.FC = () => {
  return (
    <Box
     className="our-teachers-info"
    >
            <Container>
            <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="12px"
                paddingBottom="20px"
            >
                <TitleComponent>Our Teachers</TitleComponent>
                <Typography
                    sx={{
                        color: "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18.9px",
                        letterSpacing: "-0.28px",
                        opacity: "0.5",
                    }}
                >
                    Discover what sets us apart – our key strengths that drive our success
                </Typography>
            </Box>
            </Container>
            <Box
                paddingBottom="60px"
            >
                <OurTeachersInfoSwiper/>
            </Box>
        </Box>
  )
}

export default OurTeachersInfo