import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
// import { Autoplay, EffectCoverflow, FreeMode, Pagination } from 'swiper/modules';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { TeacherBox, TeacherBoxInfo, TeacherBoxInfoContainer, TeacherBoxInfoHeader, TeacherBoxInfoHeaderScore, TeacherBoxInfoHeaderText } from './styles';
import { Box } from '@mui/material';

const OurTeachersInfoSwiper: React.FC = () => {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={
                    {
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        615: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        1500: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }
                }
                className="mySwiper"
            >
                {
                    [1, 1, 1, 1, 1, 1, 1, 1, 1].map((elem, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <Box
                                    width="100%"
                                    margin="0 auto"
                                >
                                    <TeacherBox
                                        image={require('../../media/teacher1.png')}
                                    >
                                        <TeacherBoxInfo
                                            className='teacher-box-info'
                                        >
                                            <TeacherBoxInfoContainer>
                                                <TeacherBoxInfoHeader>
                                                    <TeacherBoxInfoHeaderText>
                                                        Asadbek Urakov
                                                    </TeacherBoxInfoHeaderText>
                                                    <TeacherBoxInfoHeaderScore>
                                                        <p>SAT english</p>
                                                        <span>730</span>
                                                    </TeacherBoxInfoHeaderScore>
                                                </TeacherBoxInfoHeader>
                                                <p>
                                                    Hey, I'm Ethan Williams, your language enthusiast with an IELTS score of 8.5. In our class, we'll conquer reading, writing, listening, and speaking with a mix of creativity and structure. Ready to join me in a classroom where every moment is a discovery, every challenge is an opportunity? Welcome to a space where language mastery is an ongoing adventure. 🚀📚
                                                </p>
                                            </TeacherBoxInfoContainer>
                                        </TeacherBoxInfo>
                                    </TeacherBox>
                                </Box>
                            </SwiperSlide>
                        )
                    })
                }
                {/* <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                <Box
                        width="100%"
                        margin="0 auto"
                    >
                        <TeacherBox
                            image={require('../../media/teacher1.png')}
                        >
                            <TeacherBoxInfo>
                                <TeacherBoxInfoContainer>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident animi rem inventore alias nulla suscipit qui voluptas voluptatem commodi voluptates exercitationem, quas perferendis modi. Voluptatum id fugiat quod voluptates suscipit.
                                </TeacherBoxInfoContainer>
                            </TeacherBoxInfo>
                        </TeacherBox>
                    </Box>
                </SwiperSlide> */}
            </Swiper>
        </>
    );
}

export default OurTeachersInfoSwiper