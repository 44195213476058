import React from "react";
import Marquee from "react-fast-marquee";
import { Box, Button, Typography } from "@mui/material";
import { Container } from "../../global_styles/styles";
import { Link } from "react-router-dom";

const PersonalizedBottom: React.FC = () => {
    return (
        <>
            <Box
                borderTop="4px solid #AA233B"
                borderBottom="4px solid #AA233B"
            >
                <Marquee>
                    {
                        ["FIRST ever created digital SAT platform in Uzbekistan !",
                            "Register or Login right now!",
                            "Nearly 100% percent similarity with real exam platform",
                            "All tests are FREE for SATASHKENT members"]
                            .map((elem, index) => {
                                return (
                                    <Box
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        key={index}
                                    >
                                        <Typography
                                        sx={{
                                            padding: {
                                                xs: "10px 20px",
                                                md: "40px 20px"
                                            }
                                        }}
                                        color="#AA233B"
                                        width="100%"
                                        textAlign="center"
                                        fontSize="20px"
                                        fontStyle="normal"
                                        fontWeight="700"
                                        lineHeight="19px"
                                        letterSpacing="-0.6px"
                                        minWidth={"100%"}
                                    >
                                        {elem}
                                    </Typography>
                                    </Box>
                                )
                            })
                    }

                </Marquee>
            </Box>
            <Container>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingTop="20px"
                    paddingBottom="60px"
                >
                    <Link to="enrole">
                        <Button
                            variant="contained"
                            sx={{
                                width: "326px",
                                borderRadius: "10px",
                                paddingTop: "21px",
                                paddingBottom: "21px",
                                fontSize: "20px",
                                fontWeight: "700",
                                textTransform: "none",
                                lineHeight: "19px",
                                letterSpacing: "-0.6px",
                            }}>
                            Register or login!
                        </Button>
                    </Link>
                </Box>
            </Container>
        </>
    );
}


export default PersonalizedBottom