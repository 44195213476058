import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ItemWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 54px);
    background: linear-gradient(0deg, #540412 15.48%, #7c2032 76.25%);
    @media screen and (min-width: 1100px) {
        height: calc(100vh - 80px);
    }

`

export const ItemContainer = styled.div`
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const ItemInfo = styled.div`
    height: calc(100vh - 200px);
    @media screen and (min-width: 700px) {
        display: flex;
    }
    @media screen and (min-width: 1100px) {
        gap: 100px;
    }
`

export const ItemText = styled(Box)`
    @media screen and (min-width: 700px) {
        width: 50%;
        padding: 0 50px;
        display: flex;
        max-height: calc(100vh - 140px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width: 1100px) {
        width: 500px;
        padding: 0;
    }
`

export const ItemModileButton = styled.div`
    display: inline-block;
    @media screen and (min-width: 700px) {
        display: none;
    }
`

export const ItemImageBox = styled.div`
    width: 100%;
    max-height: calc(100vh - 400px);
    overflow: auto;
    @media screen and (min-width: 700px) {
        width: 50%;
        max-height: calc(100vh - 140px);
    }
    @media screen and (min-width: 1100px) {
        width: 500px;
    }
`