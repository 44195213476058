import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { NavbarListLi, NavbarListLink, NavbarListUl, NavbarListWrapper } from './styles'
import Hamburger from 'hamburger-react'
import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'


type NavbarListType = {
    full: boolean
}

const NavbarList: React.FC<NavbarListType> = ({full}) => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div>
                {(!full)&&<IconButton
                    aria-label="add to shopping cart"
                    sx={{
                        color: "#fff",
                        padding: "0"
                    }}
                >
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </IconButton>}
            <NavbarListWrapper open={isOpen} full={full}>
                <NavbarListUl full={full}>
                    <NavbarListLi>
                        <NavbarListLink full={full} to="/">Why us</NavbarListLink>
                    </NavbarListLi>
                    <NavbarListLi>
                        <NavbarListLink full={full} to="/">Courses</NavbarListLink>
                    </NavbarListLi>
                    <NavbarListLi>
                        <NavbarListLink full={full} to="/">Results</NavbarListLink>
                    </NavbarListLi>
                    <NavbarListLi>
                        <NavbarListLink full={full} to="/">FAQ</NavbarListLink>
                    </NavbarListLi>
                </NavbarListUl>
                {(!full)&&<Link to="enrole">
                    <Button
                    variant="contained"
                    onClick={() => {setOpen(false)}}
                    sx={{
                        width: "326px",
                        borderRadius: "10px",
                        paddingTop: "21px",
                        paddingBottom: "21px",
                        fontSize: "20px",
                        fontWeight: "700",
                        textTransform: "none",
                        lineHeight: "19px",
                        letterSpacing: "-0.6px",
                        backgroundColor: "#fff",
                    }}
                    color='secondary'
                    endIcon={<Box
                        sx={{
                            animation: "spin 2s linear infinite",
                            "@keyframes spin": {
                                "0%": {
                                    transform: "rotate(360deg)",
                                },
                                "100%": {
                                    transform: "rotate(0deg)",
                                },
                            },
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <path d="M21.6148 4.34676L17.317 0L10.3465 7.04996L9.28808 2.98434L3.41874 4.5522L5.94181 14.2189L1.91131 13.116L0.318359 19.0414L9.88678 21.6689L6.92538 24.6424L11.2125 29L18.2257 21.95L19.2948 26.0048L25.1642 24.4262L22.6197 14.7703L26.6502 15.8624L28.2325 9.92617L18.6854 7.33109L21.6361 4.34676H21.6148ZM16.6007 13.1376L21.6468 14.5108L16.6007 15.8732L17.9478 20.9769L14.2701 17.2356L10.5603 20.9661L11.9287 15.8624L6.904 14.4784L11.9287 13.1376L10.6031 8.06636L14.2594 11.7644L17.9478 8.03393L16.6007 13.116V13.1376Z" fill="black" />
                        </svg>
                    </Box>}
                >
                    Enroll for a class
                </Button>
                </Link>}
            </NavbarListWrapper>
        </div>
    )
}

export default NavbarList