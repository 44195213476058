import React from 'react'
import { EnroleBox, EnroleContainer, EnroleForm, EnroleFormElements, EnroleInfo, EnroleLink, EnroleLinkPoint, EnroleLinks, EnroleWrapper } from './styles'
import { Box, Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const EnrolePage: React.FC = () => {

  const navigate = useNavigate()

  const onSubmit = () => {
    navigate('/')
  }

  return (
    <EnroleWrapper>
      <EnroleContainer>
        <EnroleBox>
          <EnroleInfo>
            <EnroleLinks>
              <EnroleLink to="/">Main page</EnroleLink>
              <EnroleLinkPoint />
              <EnroleLink to="/">Register page</EnroleLink>
            </EnroleLinks>
            <h5>Want to achieve your dream university? Welcome to SATASHKENT!</h5>
          </EnroleInfo>
          <EnroleForm>
            <EnroleFormElements>
              <Box
                paddingBottom="40px"
              >
                <TextField fullWidth id="standard-basic" label="Name" variant="standard" />
              </Box>
              <Box
                paddingBottom="40px"
              >
                <TextField fullWidth id="standard-basic" label="Surname" variant="standard" />
              </Box>
              <Box>
                <TextField fullWidth id="standard-basic" label="Phone number" variant="standard" />
              </Box>
            </EnroleFormElements>
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{
                width: "100%",
                borderRadius: "10px",
                paddingTop: "21px",
                paddingBottom: "21px",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "none",
                lineHeight: "19px",
                letterSpacing: "-0.6px",
              }}
            >
              Submit
            </Button>
            <p>We will call back very  soon!</p>
          </EnroleForm>
        </EnroleBox>
      </EnroleContainer>
    </EnroleWrapper>
  )
}

export default EnrolePage