import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const spin = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

type CustomButtonWrapperProps = {
    width?: string
    fillColor?: string
    backgroundColor?: string
    color?: string
}

export const CustomButtonWrapper = styled.div<CustomButtonWrapperProps>`
    border-radius: 10px;
    background: ${props => props.backgroundColor || "#AA233B"};
    padding: 12px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    &.white{
        background: ${props => props.backgroundColor || "#FFF"};
    }
    p {
        color: ${props => props.color || "#FFF"};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: -0.6px;
    }
    svg {
        animation: ${spin} 2s linear infinite;
        fill: ${props => props.fillColor || "#fff"};
    }
`