import React from 'react';
import {
  ThemeProvider as MuiTheme
} from "@mui/material/styles";
import { lightTheme } from './theme/mui/light';
import { CssBaseline } from "@mui/material";
import MainPage from './pages/MainPage';
import { Route, Routes } from 'react-router-dom';
import WrapperPage from './pages/WrapperPage';
import EnrolePage from './pages/EnrolePage';
import NotFoundPage from './pages/NotFoundPage';

const App: React.FC = () => {
  return (
    <MuiTheme theme={lightTheme}>
      <CssBaseline />
      <Routes>
        <Route path='/' element={<WrapperPage />}>
          <Route index element={<MainPage />}/>
          <Route path='enrole' element={<EnrolePage />}/>
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </MuiTheme>
  );
}

export default App;
