import React, { Suspense, lazy } from 'react'
import { Loading } from '../components'
import { Outlet } from 'react-router-dom';

const Header = lazy(() => import('../components/Header'));
const Footer = lazy(() => import('../components/Footer'));

const WrapperPage: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Header/>
        <Outlet/>
      <Footer/>
    </Suspense>
  )
}

export default WrapperPage