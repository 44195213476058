import React from 'react'
import { Container, TitleComponent } from '../../global_styles/styles'
import { Box, Typography } from '@mui/material'

const Personalized: React.FC = () => {
    return (
        <Container>
            <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="12px"
                paddingBottom="40px"
            >
                <TitleComponent>Personalized platform</TitleComponent>
                <Typography
                    sx={{
                        color: "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18.9px",
                        letterSpacing: "-0.28px",
                        opacity: "0.5",
                    }}
                >
                    Solve tests from our platform specifically created for our students  in order  to give max from our edu system
                </Typography>
            </Box>
            <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                // flexDirection="column"
                // gap="12px"
                paddingBottom="40px"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: "12px", md: "28px" },
                }}
            >
                <Box>
                    <img loading="lazy" src={require("./../../media/pc1.png")} alt="pc1" />
                </Box>
                <Box>
                    <img loading="lazy" src={require("./../../media/pc2.png")} alt="pc2" />
                </Box>
            </Box>
        </Container>
    )
}

export default Personalized