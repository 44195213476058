import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StudentResultsWrapper = styled(Box)`
    @media screen and (min-width: 700px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
`

export const StudentList = styled.div`
    min-width: 200px;
    padding-bottom: 15px;
    @media screen and (min-width: 700px){
        align-self: stretch;
        padding-bottom: 0;
    }
    @media screen and (min-width: 1020px){
        min-width: 300px;
    }
`

export const StudentBox = styled(Box)`
    width: calc(100% - 20px);
    background-color: #EBECED;
    border-radius: 10px;
    height: 56px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
`

export const StudentBoxImage = styled.img`
    width: 46px;
    height: 46px;
    display: inline-block;
    border-radius: 50%;
`

export const StudentBoxName = styled(Box)`
    p{
        /* color: #FFF; */
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.28px;
    }
    span{
        /* color: #FFF; */
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16.2px;
        letter-spacing: -0.24px;
        opacity: 0.5;
    }
`

export const StudentImgInfo = styled(Box)`
    @media screen and (min-width: 700px) {
        display: flex;
        gap: 20px;
    }
`

export const UniversitetBox = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: #EBECED;
    position: relative;
    margin-bottom: 10px;
`

export const UniversitetBoxInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const UniversitetBoxInfoText = styled.div`
    span {
        display: inline-block;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    p {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`

export const UniversitetBoxInfoIcon = styled.span`
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
    @media screen and (min-width: 700px) {
        position: static;
    }
`

export const FullTuition = styled.div`
    width: 100%;
    border-radius: 10px;
    background: #AA233B;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const FullTuitionText = styled.div`
    color: #fff;
    font-style: normal;
    line-height: normal;
    span {
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        padding-bottom: 2px;
    }
    p {
        font-size: 20px;
        font-weight: 700;
    }
`