import React from 'react'
import { FooterNavList, FooterNavListItem, FooterWrapper } from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { Container } from '../../global_styles/styles'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <Container>
                <Box sx={{ width: '100%' }}>
                    <Stack
                        spacing={2}
                        sx={
                            {
                                padding: {
                                    xs: '32px 0 60px 0',
                                    sm: '32px 0 60px 0',
                                    md: '82px 0 82px 0',
                                    lg: '82px 0 82px 0',
                                },
                                flexDirection: {
                                    md: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start"
                                }
                            }}
                    >
                        <Box
                            paddingBottom="45px"
                        >
                            <img src={require("./../../media/footer_logo.png")} alt="footer-logo" />
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                gutterBottom
                                color="#fff"
                                sx={{
                                    color: "#fff",
                                    fontSize: "24px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                    letterSpacing: "-0.72px"
                                }}
                            >
                                SATASHKNET
                            </Typography>
                            <FooterNavList>
                                <FooterNavListItem>
                                    <Link to={'/'}>Why us?</Link>
                                </FooterNavListItem>
                                <FooterNavListItem>
                                    <Link to={'/'}>Results</Link>
                                </FooterNavListItem>
                                <FooterNavListItem>
                                    <Link to={'/'}>Courses</Link>
                                </FooterNavListItem>
                                <FooterNavListItem>
                                    <Link to={'/'}>Teachers</Link>
                                </FooterNavListItem>
                                <FooterNavListItem>
                                    <Link to={'/'}>Personalized platform</Link>
                                </FooterNavListItem>
                                <FooterNavListItem>
                                    <Link to={'/'}>FAQ</Link>
                                </FooterNavListItem>
                            </FooterNavList>
                        </Box>
                        <Box>
                            <Box>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    color="#fff"
                                    sx={{
                                        color: "#fff",
                                        fontSize: "24px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "24px",
                                        letterSpacing: "-0.72px",
                                    }}
                                >
                                    Our social media
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={"14px"}
                                    paddingTop={"16px"}
                                >
                                    <Box>
                                        <a href="http://www.facebook.com">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.5">
                                                    <path d="M27.5 20.2375C27.5 24.7875 24.7875 27.5 20.2375 27.5H18.75C18.0625 27.5 17.5 26.9375 17.5 26.25V19.0375C17.5 18.7 17.775 18.4125 18.1125 18.4125L20.3125 18.375C20.4875 18.3625 20.6375 18.2375 20.675 18.0625L21.1125 15.675C21.15 15.45 20.975 15.2375 20.7375 15.2375L18.075 15.275C17.725 15.275 17.45 15 17.4375 14.6625L17.3875 11.6C17.3875 11.4 17.55 11.225 17.7625 11.225L20.7625 11.175C20.975 11.175 21.1375 11.0125 21.1375 10.8L21.0875 7.79999C21.0875 7.58749 20.925 7.425 20.7125 7.425L17.3375 7.47501C15.2625 7.51251 13.6125 9.2125 13.65 11.2875L13.7125 14.725C13.725 15.075 13.45 15.35 13.1 15.3625L11.6 15.3875C11.3875 15.3875 11.225 15.55 11.225 15.7625L11.2625 18.1375C11.2625 18.35 11.425 18.5125 11.6375 18.5125L13.1375 18.4875C13.4875 18.4875 13.7625 18.7625 13.775 19.1L13.8875 26.225C13.9 26.925 13.3375 27.5 12.6375 27.5H9.7625C5.2125 27.5 2.5 24.7875 2.5 20.225V9.7625C2.5 5.2125 5.2125 2.5 9.7625 2.5H20.2375C24.7875 2.5 27.5 5.2125 27.5 9.7625V20.2375Z" fill="white" />
                                                </g>
                                            </svg>
                                        </a>
                                    </Box>
                                    <Box>
                                        <a href="http://www.facebook.com">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.5">
                                                    <path d="M21.25 5H8.75C5 5 2.5 7.5 2.5 11.25V18.75C2.5 22.5 5 25 8.75 25H21.25C25 25 27.5 22.5 27.5 18.75V11.25C27.5 7.5 25 5 21.25 5ZM17.3625 16.2875L14.275 18.1375C13.025 18.8875 12 18.3125 12 16.85V13.1375C12 11.675 13.025 11.1 14.275 11.85L17.3625 13.7C18.55 14.425 18.55 15.575 17.3625 16.2875Z" fill="white" />
                                                </g>
                                            </svg>
                                        </a>
                                    </Box>
                                    <Box>
                                        <a href="http://www.facebook.com">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.5">
                                                    <path d="M20.2375 2.5H9.7625C5.2125 2.5 2.5 5.2125 2.5 9.7625V20.225C2.5 24.7875 5.2125 27.5 9.7625 27.5H20.225C24.775 27.5 27.4875 24.7875 27.4875 20.2375V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5ZM15 19.85C12.325 19.85 10.15 17.675 10.15 15C10.15 12.325 12.325 10.15 15 10.15C17.675 10.15 19.85 12.325 19.85 15C19.85 17.675 17.675 19.85 15 19.85ZM22.4 8.6C22.3375 8.75 22.25 8.8875 22.1375 9.0125C22.0125 9.125 21.875 9.2125 21.725 9.275C21.575 9.3375 21.4125 9.375 21.25 9.375C20.9125 9.375 20.6 9.25 20.3625 9.0125C20.25 8.8875 20.1625 8.75 20.1 8.6C20.0375 8.45 20 8.2875 20 8.125C20 7.9625 20.0375 7.8 20.1 7.65C20.1625 7.4875 20.25 7.3625 20.3625 7.2375C20.65 6.95 21.0875 6.8125 21.4875 6.9C21.575 6.9125 21.65 6.9375 21.725 6.975C21.8 7 21.875 7.0375 21.95 7.0875C22.0125 7.125 22.075 7.1875 22.1375 7.2375C22.25 7.3625 22.3375 7.4875 22.4 7.65C22.4625 7.8 22.5 7.9625 22.5 8.125C22.5 8.2875 22.4625 8.45 22.4 8.6Z" fill="white" />
                                                </g>
                                            </svg>
                                        </a>
                                    </Box>
                                    <Box>
                                        <a href="http://www.facebook.com">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.5">
                                                    <path d="M27.1504 7.85044V9.31294C27.1504 10.5379 26.6629 11.5879 25.8004 12.2004C25.2629 12.6004 24.6004 12.7879 23.9129 12.7879C23.4879 12.7879 23.0629 12.7254 22.6254 12.5754L15.9004 10.3379V22.5004C15.9004 25.7754 13.2379 28.4379 9.96289 28.4379C6.68789 28.4379 4.02539 25.7754 4.02539 22.5004C4.02539 19.2254 6.68789 16.5629 9.96289 16.5629C11.5379 16.5629 12.9629 17.1879 14.0254 18.1879V5.00044C14.0254 3.78794 14.5254 2.73794 15.3879 2.11294C16.2504 1.50044 17.4004 1.36294 18.5504 1.73794L24.0754 3.58794C25.7754 4.15044 27.1504 6.06294 27.1504 7.85044Z" fill="white" />
                                                </g>
                                            </svg>
                                        </a>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    color="#fff"
                                    sx={{
                                        color: "#fff",
                                        fontSize: "24px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "24px",
                                        letterSpacing: "-0.72px",
                                        paddingTop: "10px",
                                        marginBottom: "20px"
                                    }}
                                >
                                    Our contacts
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24.3px",
                                        letterSpacing: "-0.18px",
                                        opacity: "0.5",
                                        color: "#fff"
                                    }}
                                >
                                    +998 78 777 77 74
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Container >
        </FooterWrapper >
    )
}

export default Footer