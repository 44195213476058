import React from 'react'
import { HeaderWrapper } from './styles'
import { Container } from '../../global_styles/styles'
import Stack from '@mui/material/Stack'
import { HelperLanguage } from '../../helper/components'
import LogoComponent from '../../helper/components/LogoComponent'
import NavbarList from '../NavbarList'
import useMediaQuery from '@mui/material/useMediaQuery';

const Header: React.FC = () => {

  const matches = useMediaQuery('(min-width: 1100px)');

  return (
    <HeaderWrapper>
        <Container>
            <Stack
                direction={matches?"row-reverse":"row"}
                justifyContent="space-between"
                alignItems="center"
                height={matches?"80px":"54px"}
                position="relative"
            >
                <HelperLanguage/>
                <LogoComponent full={matches}/>
                <NavbarList full={matches}/>
            </Stack>
        </Container>
    </HeaderWrapper>
  )
}

export default Header