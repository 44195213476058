import React from 'react'
import { ItemContainer, ItemImageBox, ItemInfo, ItemModileButton, ItemText, ItemWrapper } from './styles'
import { Box, Typography } from '@mui/material'
import { CustomButton } from '../../helper/components'
import { Link } from 'react-router-dom'

const ItemThree: React.FC = () => {
    return (
        <ItemWrapper>
            <ItemContainer>
                <ItemInfo>
                    <ItemText
                        paddingBottom="25px"
                    >
                        <Typography
                            color="#FFF"
                            textAlign="center"
                            fontSize="32px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="normal"
                            paddingBottom="8px"
                            sx={{
                                "@media (min-width: 1290px)": {
                                    fontSize: "48px",
                                }
                            }}
                        >
                            Our teachers
                        </Typography>
                        <Typography
                            color="rgba(255, 255, 255, 0.70)"
                            textAlign="center"
                            fontSize="14px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="normal"
                            sx={{
                                "@media (min-width: 1290px)": {
                                    fontSize: "18px",
                                }
                            }}
                        >
                            Lets check out what should you do before getting into your dream university, after all of the effort that you will put while studying with us, you will achieve what you have planned Lets check out what should you do before getting into your dream university, after all of the effort that you will put while studying with us, you will achieve what you have planned
                        </Typography>
                        <Box
                            sx={{
                                display: "none",
                                paddingTop: "30px",
                                "@media (min-width: 700px)": {
                                    display: "block",
                                }
                            }}
                        >
                            <Link to="enrole">
                                <CustomButton fillColor="#000" backgroundColor="#fff" color="#000">Enroll for a class</CustomButton>
                            </Link>
                        </Box>
                    </ItemText>
                    <ItemImageBox>
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingBottom="12px"
                            sx={{
                                "img": {
                                    display: "inline-block",
                                    width: "100%",
                                    borderRadius: "20px"
                                }
                            }}
                        >
                            <img src={require('../../media/item-tree-image1.png')} alt="item-two-image" />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            gap="12px"
                        >
                            <Box
                                display="inline-block"
                                sx={{
                                    width: "100%",
                                    "img": {
                                        display: "inline-block",
                                        borderRadius: "20px",
                                        width: "100%"
                                    }
                                }}
                            >
                                <img src={require('../../media/item-tree-image2.png')} alt="item-two-image" />
                            </Box>
                            <Box
                                display="inline-block"
                                sx={{
                                    width: "100%"
                                }}
                            >
                                <Box
                                    display="inline-block"
                                    width="100%"
                                    sx={{
                                        "img": {
                                            display: "inline-block",
                                            width: "100%"
                                        }
                                    }}
                                >
                                    <img src={require('../../media/item-two-image3.png')} alt="item-two-image" />
                                </Box>
                                <Box
                                    display="inline-block"
                                    sx={{
                                        width: "100%",
                                        "img": {
                                            display: "inline-block",
                                            width: "100%"
                                        }
                                    }}
                                >
                                    <img src={require('../../media/item-tree-image3.png')} alt="item-two-image" />
                                </Box>
                            </Box>
                        </Box>
                    </ItemImageBox>
                </ItemInfo>
                <ItemModileButton>
                    <Link to="enrole">
                        <CustomButton fillColor="#000" backgroundColor="#fff" color="#000">Enroll for a class</CustomButton>
                    </Link>
                </ItemModileButton>
            </ItemContainer>
        </ItemWrapper>
    )
}

export default ItemThree