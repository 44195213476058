import React, { useState } from 'react'
import { TeacherCourseBox } from './styles'
import { CustomButton } from '../../helper/components'
import Slider from "react-slick"
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

const OurTeacherSlick: React.FC = () => {

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 3,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    vertical: false
                }
            },
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    vertical: false
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false
                }
            }
        ],
    };

    return (
        <Box
            overflow="hidden"
            sx={{
                paddingBottom: {
                    xs: "60px",
                    md: "75px"
                }
            }}
        >
            <Slider {...settings}>
                <TeacherCourseItem/>
                <TeacherCourseItem/>
                <TeacherCourseItem/>
            </Slider>
        </Box>
    )
}

const TeacherCourseItem: React.FC = () => {

    const [hover, setHover] = useState(false);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <TeacherCourseBox 
                className='teache-course'
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className='teache-course-header'>
                    <svg width="49" height="45" viewBox="0 0 49 45">
                        <path d="M44.2139 8.89345V30.6976C44.2139 32.4764 42.6263 34.1085 40.677 34.3285L40.054 34.4019C36.7583 34.8053 32.116 36.0523 28.3782 37.4827C27.0719 37.9778 25.625 37.0793 25.625 35.7773V10.2688C25.625 9.5903 26.047 8.9668 26.7102 8.63672C30.3878 6.82124 35.9544 5.20747 39.7325 4.91406H39.8531C42.2646 4.91406 44.2139 6.69287 44.2139 8.89345Z" />
                        <path d="M21.5219 8.63672C17.8443 6.82124 12.2777 5.20747 8.49958 4.91406H8.35891C5.94737 4.91406 3.99805 6.69287 3.99805 8.89345V30.6976C3.99805 32.4764 5.58564 34.1085 7.53497 34.3285L8.15794 34.4019C11.4537 34.8053 16.0959 36.0523 19.8338 37.4827C21.14 37.9778 22.587 37.0793 22.587 35.7773V10.2688C22.587 9.57196 22.185 8.9668 21.5219 8.63672ZM10.047 14.1932H14.5686C15.3926 14.1932 16.0758 14.8167 16.0758 15.5686C16.0758 16.3388 15.3926 16.9439 14.5686 16.9439H10.047C9.22304 16.9439 8.53977 16.3388 8.53977 15.5686C8.53977 14.8167 9.22304 14.1932 10.047 14.1932ZM16.0758 22.4454H10.047C9.22304 22.4454 8.53977 21.8402 8.53977 21.07C8.53977 20.3181 9.22304 19.6946 10.047 19.6946H16.0758C16.8998 19.6946 17.583 20.3181 17.583 21.07C17.583 21.8402 16.8998 22.4454 16.0758 22.4454Z" />
                    </svg>
                    <h5>SAT 1500+ </h5>
                </div>
                <ul className="teache-course-info-list">
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Expert-Led Sessions: Educators with SAT scores of 1570</p>
                    </li>
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Intensive Learning: 6 lessons per week each 2 hours long</p>
                    </li>

                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Intensive Learning: 6 lessons per week each 2 hours long</p>
                    </li>
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Intensive Learning: 6 lessons per week each 2 hours long</p>
                    </li>
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Intensive Learning: 6 lessons per week each 2 hours long</p>
                    </li>
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Intensive Learning: 6 lessons per week each 2 hours long</p>
                    </li>
                    <li>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path d="M15.2585 2.99776L12.1792 0L7.18499 4.86204L6.42666 2.05816L2.22137 3.13945L4.02911 9.80612L1.14133 9.04549L0 13.132L6.85561 14.9441L4.73382 16.9948L7.80544 20L12.8303 15.138L13.5963 17.9344L17.8016 16.8456L15.9786 10.1864L18.8663 10.9396L20 6.84564L13.1597 5.05593L15.2738 2.99776H15.2585ZM11.666 9.0604L15.2815 10.0075L11.666 10.9471L12.6312 14.4668L9.99617 11.8867L7.33819 14.4594L8.31865 10.9396L4.7185 9.98508L8.31865 9.0604L7.36883 5.56301L9.98851 8.11335L12.6312 5.54064L11.666 9.04549V9.0604Z" />
                            </svg>
                        </span>
                        <p>Exclusive Bonus: Embark on your college application journey with our FREE admission course on the CommonAPP and CSS Profile</p>
                    </li>
                </ul>
                <Link to="enrole">
                    <CustomButton fillColor={hover?"#000":""} backgroundColor={hover?"#fff":""} color={hover?"#000":""}>Enroll for a class</CustomButton>
                </Link>
            </TeacherCourseBox>
        </Box>
    )
}

export default OurTeacherSlick