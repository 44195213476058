import styled from "@emotion/styled";

type TeacherBoxProps = {
    image: string
}

export const TeacherBox = styled.div<TeacherBoxProps>`
    width: 300px;
    height: 480px;
    flex-shrink: 0;
    border-radius: 20px;
    background: url(${props => props.image}) lightgray 50% / cover no-repeat;
    margin-bottom: 52px;
    margin: 0 auto;
    /* margin: 0 15px; */
    position: relative;
    @media screen and (min-width: 1000px) {
        width: 500px;
        height: 650px;
    }
    :hover {
        .teacher-box-info {
            display: block;
        }
    }
`

export const TeacherBoxInfo = styled.div`
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 14px;
    display: none;
`

export const TeacherBoxInfoContainer = styled.div`
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 12px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2.4000000953674316px);
    /* width: 100%; */
    p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`

export const TeacherBoxInfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
`

export const TeacherBoxInfoHeaderText = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const TeacherBoxInfoHeaderScore = styled.div`
    p {
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    span {
        color: #FFF;
        font-size: 28.8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`