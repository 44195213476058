import { ThemeOptions, createTheme } from '@mui/material/styles';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#AA233B',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fff'
    }
  }
};

export const lightTheme = createTheme(lightThemeOptions);