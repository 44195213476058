const theme = {
    fonts: {
      body: 'Lato, sans-serif',
    },
    colors: {
      primary: '#AA233B',
    }
  };
  
  // export type ThemeProps = {
  //   theme: Theme;
  // };
  
  export default theme;