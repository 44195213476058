import styled from "@emotion/styled";
import swipeable_bg1 from "../../media/swipeable_bg1.png";
import swipeable_bg2 from "../../media/swipeable_bg2.png";

export const ResultsItemWrapper = styled.div`
    width: 100%;
    /* height: 300px; */
    padding: 0 15px;
    @media screen and (min-width: 800px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;

        background-image: url(${swipeable_bg1}) , url(${swipeable_bg2});
        background-position: 4px calc(100% - 87px), center bottom;
        background-repeat: no-repeat;
    }
`

export const ResultsItemImageScore = styled.div`
    width: 100%;
    @media screen and (min-width: 800px) {
        width: calc(60% - 10px);
    }
    @media screen and (min-width: 1000px) {
        width: 560px;
    }
`

export const ResultsItemImage = styled.img`
    width: 100%;
    border-radius: 20px;
    border: 3px solid #000;
`

export const ResultsItemScore = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    gap: 10px;
`

export const ResultsItemScoreBox = styled.div`
    min-width: 150px;
    width: calc(50% - 10px);
    border-radius: 5px;
    background: #AA233B;
    padding: 5px 6px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 55px;
    @media screen and (min-width: 800px) {
        border-radius: 10px;
        height: 110px;
    }
`

export const ResultsItemInfo = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    @media screen and (min-width: 800px) {
        width: calc(40% - 10px);
        padding-top: 0;
    }
    @media screen and (min-width: 1000px) {
        width: 390px;
    }
`

export const ResultsItemInfoButtons = styled.div`
    display: flex;
    gap: 15px;
    padding: 5px 0;
`

export const ResultsItemInfoButton = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.5s ease;
    cursor: pointer;
    :hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`