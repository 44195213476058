import { Box, Typography } from '@mui/material'
import React from 'react'
import { Container, TitleComponent } from '../../global_styles/styles'
import OurTeacherSlick from './OurTeacherSlick'

type Props = {
    title: string,
    description: string,
    courses: {
        course: string,
        opportunities: string[]
    }[]
}

const OurTeachers: React.FC<Props> = ({title, description, courses}) => {
    return (
        <Container>
            <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="12px"
                paddingBottom="20px"
            >
                <TitleComponent>{title}</TitleComponent>
                <Typography
                    sx={{
                        color: "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18.9px",
                        letterSpacing: "-0.28px",
                        opacity: "0.5",
                    }}
                >
                    {description}
                </Typography>
            </Box>
            <OurTeacherSlick/>
        </Container>
    )
}

export default OurTeachers