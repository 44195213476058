import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { css } from '@emotion/react'

// NavbarListWrapper

type NavbarListWrapperType  = {
    open: boolean,
    full: boolean
}

const  ModileStyleNavbarListWrapper = css`
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    width: 100vw;
    height: calc(100% - 54px);
    top: 54px;
    left: 0;
    z-index: 100;
    padding-top: 42px;
    padding-bottom: 25px;
`

const DesctopStyleNavbarListWrapper = css`
    transform: none !important;
    display: block !important;
`

export const NavbarListWrapper = styled.div<NavbarListWrapperType>`
    display: flex;
    background-color: ${props => props.theme.colors.primary};
    transform: ${props => props.open ? "translateX(0)" : "translateX(-100vw)"};
    ${props => (!props.full)?ModileStyleNavbarListWrapper:DesctopStyleNavbarListWrapper}
`

// NavbarListUl

type NavbarListUlType  = {
    full?: boolean
}

const ModileNavbarListUl = css`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 42px;
`

const DesctopNavbarListUl = css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`

export const NavbarListUl = styled.ul<NavbarListUlType>`
    width: 100%;
    display: flex;
    ${props => (!props.full)?ModileNavbarListUl:DesctopNavbarListUl}
`


// NavbarListLi

export const NavbarListLi = styled.li`
    text-align: center;
`


// NavbarListLink

type NavbarListLinkType  = {
    full?: boolean
}

const MobileNavbarListLink = css`
    display: inline-block;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

const DesctopNavbarListLink = css`
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: -0.32px;
`

export const NavbarListLink = styled(Link)<NavbarListLinkType>`
    ${props => (!props.full)?MobileNavbarListLink:DesctopNavbarListLink}

`