
import { Box } from '@mui/material';
import React, { useRef } from 'react';
import ItemOne from './ItemOne';
import ItemTwo from './ItemTwo';
import ItemThree from './ItemThree';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const WhyUs: React.FC = () => {

  const progressCircle = useRef<SVGSVGElement>(null);
  const progressContent = useRef<HTMLSpanElement>(null);
  const onAutoplayTimeLeft = (swiper: any, time: number, progress: any) => {
    if (progressCircle.current && progressContent.current) {
      progressCircle.current.style.setProperty('--progress', `${1 - progress}`);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  return (
    <Box 
      className="why-us"
      paddingBottom="150px"
    >
      <Swiper 
        spaceBetween={50}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
          <ItemOne/>
        </SwiperSlide>
        <SwiperSlide>
          <ItemTwo/>
        </SwiperSlide>
        <SwiperSlide>
          <ItemThree/>
        </SwiperSlide>
          <div className="autoplay-progress">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
              <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
          </div>
      </Swiper>
    </Box>
  )
};

export default WhyUs;
  
